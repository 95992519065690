<template>
  <div id="app" style="    background-color: #fff;">
    <section class="shahada shahada-cu">
      <div class="container-fluid">
        <!-- <img src="/assets/img/hdr.jpg" class="w-100" alt=""> -->
        <div class="row justify-content-center custem-row">
          <div class="col-md-4 pt-0">
            <div class="unique-flex-center cu-h cu-h-1 p-r">
              <div class="golden-col golden-col-1"></div>
              <div class="d-flex flex-column align-center d-md-none">
                <div class="lamp"></div>
                <div class="g-text mt-3"></div>
                <h6 class="text-secondary mt-2 font-bold" style="text-align: center !important;padding:25px !important">
                  أهداني أماناً أُهديه ختمةً قرآنية..
إكراماً لأرواح شهداء فتوى الدفاع المقدّسة والقوّات الأمنية، حملة لإهداء ختمات قرآنية لأرواح الشهداء الطاهرة كجزءٍ من الوفاء لهم.
                </h6>
              </div>

              <div class="rectangle-in mt-3 cu-scroll">
                <div class="decorative-frame">
                  <svg viewBox="0 0 500 540">
                    <defs>
                      <clipPath id="myClipPath">
                        <!-- شكل الزخرفة -->
                        <path class="st0" d="M70.9,422.3c-5.7-1.5-10.1-2.9-13.1-4.3c-22.7-10.3-33.8-28.1-33.2-53.4c0.1-2.6,0.3-5.2,0.7-7.9
								c0.1-0.4,0-0.7-0.3-1c-8.5-8.2-14.8-18-18.9-29.3c-6.6-18.5-7.6-39.2-4.6-58.5C4.4,250.1,11.9,233,25,220.7c0.3-0.3,0.4-0.6,0.4-1
								c-0.2-2-0.3-4-0.5-6c-2.6-30.6,16.7-53.5,45.9-59.5c0.4-0.1,0.6-0.3,0.7-0.7c5.5-26.3,29.6-41.8,54.6-48c2.8-0.7,6.1-1.4,9.9-2
								c17.3-3,34.4-10,48.3-19.4c15.5-10.4,27.5-23.8,36-40.2c1.8-3.4,3.3-7,4.4-10.7c3.1-10.5,6.4-21.5,9.9-32.8c0.1-0.2,0.3-0.4,0.5-0.4
								c0.3,0,0.5,0.2,0.6,0.6c3.1,10.3,6.3,20.7,9.4,31c1.5,5,3.2,9.3,5,12.8c6.9,13.1,16.2,24.5,27.7,33.7c15.4,12.2,32.9,20.4,52.7,24.6
								c3.9,0.8,7.8,1.6,11.7,2.4c12.5,2.5,23.9,7.6,34.3,15.1c11.6,8.4,18.9,19.4,22,32.9c0.1,0.4,0.4,0.7,0.8,0.8
								c32.4,7.6,49.9,31.5,45.5,65.4c-0.1,0.5,0.1,0.8,0.4,1.2c21.4,20.7,27.2,52,24.2,81.6c-2.1,20.1-9.6,39.4-24.1,53.4
								c-0.4,0.4-0.6,0.9-0.6,1.5c0.9,9.5,1,17.9-1.7,27.4c-6,20.9-23.4,32.9-43.9,37.8c-0.3,0.1-0.5,0.3-0.5,0.6
								c-6.3,29-33.1,43.6-60.1,49c-9.5,1.9-14.7,3-15.5,3.2c-18.8,5.6-34.6,13.8-47.5,24.8c-10.5,9-18.9,19.6-25.2,31.6
								c-2.4,4.7-4.5,10-6.2,15.9c-2.9,9.9-5.7,19.5-8.6,28.6c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.4-0.1-0.4-0.3c-3-9.2-5.8-18.3-8.5-27.4
								c-2-7-4.4-12.9-6.9-17.7c-10-18.9-26.3-34.6-44.9-44.5c-11.3-6-22.8-10.2-34.5-12.7c-4.4-0.9-8.5-1.8-12.4-2.6
								c-9.4-2-18.6-5.6-27.5-10.8c-14.4-8.4-24.5-20.7-28.3-37.1C71.5,422.6,71.3,422.4,70.9,422.3z" />
                      </clipPath>
                    </defs>
                    <image :xlink:href="martyr.img" width="100%" height="100%"
                      clip-path="url(#myClipPath)" style="object-position: center" />
                  </svg>
                </div>
                <h6 class="font-bold text-dark">{{martyr.name}}</h6>
                <h6 class="text-secondary f-14 font-bold my-2">
                   المحافظة : {{martyr.city}}
                </h6>
                <h6 class="text-secondary f-14 font-bold my-2">
                  تاريخ الأستشهاد : {{martyr.date_martyrdom}}
                </h6>
                <h6 class="text-secondary f-14 font-bold my-2">
                  قاطع الأستشهاد : {{martyr.place_martyrdom}}
                </h6>
                <p class="text-secondary f-14 text-justify">{{martyr.bio}}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-8 pt-0">
            <div class="custom-flex-container">
              <div class="custom-sub-container">
                <div class="lamp"></div>
                <div class="g-text mt-3"></div>
                <h6 class="text-secondary mt-2 font-bold" style="text-align: center;">
                  أهداني أماناً أُهديه ختمةً قرآنية..
إكراماً لأرواح شهداء فتوى الدفاع المقدّسة والقوّات الأمنية، حملة لإهداء ختمات قرآنية لأرواح الشهداء الطاهرة كجزءٍ من الوفاء لهم.
                </h6>
              </div>
              <div class="zack mt-3"></div>
              <h6 class="edi mt-2 font-bold">
                أحجز جزء من القران الكريم لروح الشهيد
              </h6>


             
              <div class="button-container mb-2" v-if="regjuz.length>0">

              
    <div v-for="itemx in 30" :key="itemx">
        <button v-if="regjuz.includes(itemx)" disabled class="custom-button">
            الجزء {{ itemx }}
        </button>
        <button @click="reg(itemx)" v-else  class="custom-button">
            الجزء {{ itemx }}
        </button>
   
</div>

              </div>

              <v-dialog v-model="dialog">
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title font-bold" id="exampleModalLabel">
                          هل تأكد القراءة ؟
                        </h5>
                        <button type="button" class="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <p>
                          قراءة الجزء الثاني من القرآن الكريم للشهيد احمد حسن
                          الموسوي
                        </p>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-successr">
                          تأكيد
                        </button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                          إغلاق
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </v-dialog>

            </div>
          </div>
          <div class="custom-hidden-3 col-md-2 pt-0">
            <div class="slider s2">
              <div class="slides">
                <!-- <img :src=" item.img" :alt="item.name" :key="item" v-for="item in martyrs" /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="container mt-5" id="section2">
      <div class="d-flex flex-column align-center">
      
      </div>
      <div class="row mt-3" >

        <div class="col-12"   >
          <div class="vuetify-d-flex-justify-evenly cu-edit">
     
            <div   class="reading" style="padding: 0px !important;background:none !important">
             
          
            
              <audio controls class="au"  v-if="quran.length>0" >
                <!-- <source src="https://alkafeel.net/audio/assets/mp3/2726.mp3" type="audio/mp3 " /> -->
              
                <source :src="'https://alkafeel.net/audio/assets/mp3/'+juz[parseInt(quran[0].ayah_juz_number)-1].mp3" type="audio/mp3 " />
              </audio>
            </div>
          </div>
        </div>
        <div class="col-12 d-border rounded">
          <!-- <div id="content" class="p-3 border cu-font text-center">
            احجز جزء من القران الكريم لعرضه
          </div> -->
          <div style="padding:30px" id="yourTargetDivId">
            <template >  
        <p v-if="quran.length>0" style="text-align: center;font-family: 'UthmanicHafs1Ver08';font-size: 30px;    color: #000;"> بِسۡمِ ٱللَّهِ ٱلرَّحۡمَٰنِ ٱلرَّحِيمِ ١  </p>
        </template>  
    <span style="font-family: 'UthmanicHafs1Ver08';font-size: 30px;    color: #000;" v-for="(item, index) in quran" :key="index">
        <!-- Add a conditional check for the 'text' property -->
     
    
        <template v-if="item.text">{{ item.text }}</template>
    </span>
    <button v-if="quran.length>0" class="custom-button" color="green" style="background-color: #98c83e;font-size: 20px;"  @click="remove()">تم اكمال قراءة الجزء</button>
</div>
        </div>
     
      </div>
    </div>

   
    <section>
      <div class="container">
        <div class="d-none d-md-flex flex-column align-center mt-4">
          <div class="g-text "></div>
          <h6 class="text-secondary mt-2 font-bold">
            أهداني أماناً أُهديه ختمةً قرآنية..
إكراماً لأرواح شهداء فتوى الدفاع المقدّسة والقوّات الأمنية، حملة لإهداء ختمات قرآنية لأرواح الشهداء الطاهرة كجزءٍ من الوفاء لهم.
          </h6>
          <div class="zack mt-9"></div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-lg-3 cu-col" v-for="item in martyrs" :key="item.id">
            <a :href="'/shuhadaById/'+item.id" class="no-underline">
            <div class="card">
              <div class="card-image-container">

                <!-- <img :src="'/img/' + item.img" alt="صورة" class="card-image" /> -->

                <svg viewBox="0 0 500 540" class="card-image">
                  <defs>
                    <clipPath id="myClipPath">
                      <path class="st0" d="M70.9,422.3c-5.7-1.5-10.1-2.9-13.1-4.3c-22.7-10.3-33.8-28.1-33.2-53.4c0.1-2.6,0.3-5.2,0.7-7.9
                    c0.1-0.4,0-0.7-0.3-1c-8.5-8.2-14.8-18-18.9-29.3c-6.6-18.5-7.6-39.2-4.6-58.5C4.4,250.1,11.9,233,25,220.7c0.3-0.3,0.4-0.6,0.4-1
                    c-0.2-2-0.3-4-0.5-6c-2.6-30.6,16.7-53.5,45.9-59.5c0.4-0.1,0.6-0.3,0.7-0.7c5.5-26.3,29.6-41.8,54.6-48c2.8-0.7,6.1-1.4,9.9-2
                    c17.3-3,34.4-10,48.3-19.4c15.5-10.4,27.5-23.8,36-40.2c1.8-3.4,3.3-7,4.4-10.7c3.1-10.5,6.4-21.5,9.9-32.8c0.1-0.2,0.3-0.4,0.5-0.4
                    c0.3,0,0.5,0.2,0.6,0.6c3.1,10.3,6.3,20.7,9.4,31c1.5,5,3.2,9.3,5,12.8c6.9,13.1,16.2,24.5,27.7,33.7c15.4,12.2,32.9,20.4,52.7,24.6
                    c3.9,0.8,7.8,1.6,11.7,2.4c12.5,2.5,23.9,7.6,34.3,15.1c11.6,8.4,18.9,19.4,22,32.9c0.1,0.4,0.4,0.7,0.8,0.8
                    c32.4,7.6,49.9,31.5,45.5,65.4c-0.1,0.5,0.1,0.8,0.4,1.2c21.4,20.7,27.2,52,24.2,81.6c-2.1,20.1-9.6,39.4-24.1,53.4
                    c-0.4,0.4-0.6,0.9-0.6,1.5c0.9,9.5,1,17.9-1.7,27.4c-6,20.9-23.4,32.9-43.9,37.8c-0.3,0.1-0.5,0.3-0.5,0.6
                    c-6.3,29-33.1,43.6-60.1,49c-9.5,1.9-14.7,3-15.5,3.2c-18.8,5.6-34.6,13.8-47.5,24.8c-10.5,9-18.9,19.6-25.2,31.6
                    c-2.4,4.7-4.5,10-6.2,15.9c-2.9,9.9-5.7,19.5-8.6,28.6c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.4-0.1-0.4-0.3c-3-9.2-5.8-18.3-8.5-27.4
                    c-2-7-4.4-12.9-6.9-17.7c-10-18.9-26.3-34.6-44.9-44.5c-11.3-6-22.8-10.2-34.5-12.7c-4.4-0.9-8.5-1.8-12.4-2.6
                    c-9.4-2-18.6-5.6-27.5-10.8c-14.4-8.4-24.5-20.7-28.3-37.1C71.5,422.6,71.3,422.4,70.9,422.3z" />
                    </clipPath>
                  </defs>
                  <image :xlink:href="item.img" width="100%" height="100%" clip-path="url(#myClipPath)"
                    style="object-position: center" preserveAspectRatio="xMidYMid meet" />
                </svg>
              </div>
              <div class="card-content">
                <div class="card-title" style="color:#000">{{item.name}}</div>
                <p class="card-description">
                  تاريخ الأستشهاد : {{item.date_martyrdom}}
                </p>
              </div>
              
                <div class="card-footer" v-if="item.juz.length !== 30">
                  <img class="mx-2" src="img/bell.svg" alt="" />
                  <!-- <router-link :to="'/shuhadaById/'+item.id" class="title">
                    <h6>تم اكمال {{item.juz.length}} جزء من اصل 30</h6>
                  </router-link> -->
                  <div class="title">
                    <h6>تم اكمال {{item.juz.length}} جزء من اصل 30</h6>
                  </div>
               
                </div>
                <div v-if="item.juz.length >= 30" class="card-footer card-footer-active">
                  <img class="mx-2" src="img/check.svg" alt="" />
                  <h6>تم اكمال الختمة</h6>
                </div>
              
            </div>
          </a>
          </div>
        </div>
      </div>
    </section>

    <section class="my-5">
      <hr />
      <div class="container">
        <div class="d-flex flex-column align-center mt-5">
          <div class="g-text mt-3"></div>
          <h6 class="text-secondary mt-2 font-bold">
            أهداني أماناً أُهديه ختمةً قرآنية..
إكراماً لأرواح شهداء فتوى الدفاع المقدّسة والقوّات الأمنية، حملة لإهداء ختمات قرآنية لأرواح الشهداء الطاهرة كجزءٍ من الوفاء لهم.

         
          </h6>
          <div class="zack mt-3"></div>
        </div>
        <h6 class="my-3 cu-text edi">
          “إنَّ طبيعة المخاطر المحدقة بالعراق وشعبه في الوقت الحاضر تقتضي
          الدفاع عن هذا الوطن وأهله وأعراض مواطنيه وهذا الدفاع واجب على
          المواطنين بالوجوب الكفائي، […] ومن هنا فإنَّ على المواطنين الذين
          يتمكنون من حمل السلاح ومقاتلة الإرهابيين دفاعاً عن بلدهم وشعبهم
          ومقدساتهم عليهم التطوع للإنخراط في القوات الأمنية لتحقيق هذا الغرض
          المقدس.”
        </h6>

        <h6 class="text-danger my-3 cu-text font-bold">
          “فتوى الجهاد الكفائي لسماحة المرجع الأعلى السيد علي السيستاني (دام
          ظله)- حزيران 2014 م
        </h6>
      </div>
    </section>
  </div>
</template>
<style >

@import '/assets/css/shuhada.css?v=2112233433323343421211';
</style>
<script>

  import axios from "axios";

  import Swal from "sweetalert2";
  export default {
    data() {
      return {
        ayah: [],
        
        juzIds: [],
        martyrs:[],
        quran: [],
        voice: '',
        martyrId:'',
        martyr:{},
        regjuz: [],
        juz: []
      }
    },
    created() {

      

  
      axios
        .get("https://alkafeel.net/alkafeel_back_test/api/martyr")
        .then((response) => {
          this.martyrs = response.data;
        })
        .catch((error) => {
          console.error("error : ", error);
        });

      axios
        .get("https://alkafeel.net/alkafeel_back_test/api/martyr/" + this.$route.params.id)
        .then((response) => {
          this.martyr = response.data;
        })
        .catch((error) => {
          console.error("error : ", error);
        });

   
    },
    methods: {
      remove(){
    
        document.cookie ='example_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        localStorage.removeItem("quran");
        localStorage.removeItem("voice");
        location.reload();
      },


      getquran(){
return localStorage.getItem('quran');
      },
      getvoice(){
return localStorage.getItem('voice');
      },
      get_regjuz(id) {
    
      axios
        .get("https://alkafeel.net/alkafeel_back_test/api/martyr_jas_reg/" +id)
        .then((response) => {
          this.martyr_jas_reg = response.data;
          axios
        .get("https://alkafeel.net/alkafeel_back_test/api/juz")
        .then((response) => {
          this.juz = response.data;
        })
        .catch((error) => {
          console.error("error : ", error);
        });
          const juzIds = [];

      // Iterate over the array of objects
      this.martyr_jas_reg.forEach(item => {
        // Extract the juz_id property from each object and push it into the juzIds array
        juzIds.push(item.juz_id);
      });


          this.regjuz =juzIds;
          this.regjuz=  this.regjuz.map(juzId => parseInt(juzId));

     
       

     
        })
        .catch((error) => {
          console.error("error : ", error);
        });
    },
      getqurans() {
       // Retrieve Quran data from localStorage
       const quranData = localStorage.getItem('quran');
        if (quranData) {
            // Parse the JSON string into an array
            this.quran = JSON.parse(quranData);

            // Scroll to the target div after setting the Quran data
            this.$nextTick(() => {
                // const targetDiv = document.getElementById('yourTargetDivId');
                if (targetDiv) {
                    // targetDiv.scrollIntoView({ behavior: 'smooth' });
                } else {
                   
                }
            });
        } else {
      
        }
    },
      reg(juzId) {

   
        var cookies = document.cookie.split(';');
var found = false;
cookies.forEach(function (cookie) {
    if (cookie.trim().startsWith("example_cookie")) {
        found = true;
    }
});
if (found) {
    Swal.fire({
        icon: "error",
        title: "",
        timer: 2000, 
        text: "يمكنك حجز جزء واحد خلال اليوم",
    });
} else {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-successr",
            cancelButton: "btn btn-dangerr"
        },
        buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
        title: "هل تأكد قرائتك للجزء ؟",
        text: "",
        showCancelButton: true,
        confirmButtonText: "نعم ، أؤكد",
        cancelButtonText: "لا", 
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
    
            axios.post("https://alkafeel.net/alkafeel_back_test/api/reserve", {
                martyrId:this.$route.params.id,
                juzId: juzId,
            })
            .then((response) => {
               
                this.get_regjuz(this.$route.params.id);

                localStorage.setItem("quran", JSON.stringify(response.data.jas[0].ayah));
                localStorage.setItem("voice", response.data.jas[0].mp3);

        // Retrieve the quran array from localStorage
        this.quran = localStorage.getItem("quran");
        this.voice = localStorage.getItem("voice");


                // console.log(this.quran)
                // console.log(this.voice)
                var d = new Date();
                d.setTime(d.getTime() + (24 * 60 * 60 * 1000)); // 24 hours
                var expires = "expires=" + d.toUTCString();
                document.cookie = "example_cookie=test; " + expires + "; path=/";
                // console.log("Cookie set!");
                this.$nextTick(() => {
                    document.getElementById('section2').scrollIntoView({ behavior: 'smooth' });
                });
                swalWithBootstrapButtons.fire({
                    title: "نجاح!",
                    text: "تم حجز الجزء بنجاح",
                    icon: "success"
                });

                this.getqurans();
            })
            .catch((error) => {
                console.error("Error while reserving juz:", error);
            });
        } 
    });
}

      }

    },
    mounted() {
this.getqurans();
this.getvoice();

axios
    .get("https://alkafeel.net/alkafeel_back_test/api/martyrId/" + this.$route.params.id)
    .then((response) => {
    
      this.martyr = response.data;
      this.martyrId = this.$route.params.id;
  
      this.get_regjuz(this.$route.params.id);
    })
    .catch((error) => {

    });

   
    
    },
    updated() {
      if (this.$route.hash == '#section2') {
        document.getElementById('section2').scrollIntoView({ behavior: 'smooth' });
      }
    }

  }
</script>















